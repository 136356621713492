import React from 'react'
import { PropTypes } from 'prop-types'
import { Check, Clock, X } from 'react-feather'
import styled from 'styled-components'
import { theme } from 'theme'

const Typography = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
`

function TxStatusText({ status }) {
  const { blue1, success, error } = theme()

  if (status === 'pending') {
    return (
      <Typography>
        <Clock color={blue1} />
        Pending
      </Typography>
    )
  }

  if (status?.includes('processing')) {
    return (
      <Typography>
        <Clock color={blue1} />
        Processing
      </Typography>
    )
  }

  if (status === 'success') {
    return (
      <Typography>
        <Check color={success} />
        Success
      </Typography>
    )
  }

  if (status === 'failed') {
    return (
      <Typography>
        <X color={error} />
        Failed
      </Typography>
    )
  }

  return status
}

TxStatusText.propTypes = {
  status: PropTypes.string.isRequired,
}

export default TxStatusText
