import React, { useCallback } from 'react'
import { ethers } from 'ethers'
import styled from 'styled-components'
import {
  Button,
  ButtonDropdownGrey,
  ButtonGray,
  ButtonOutlined,
} from 'components/Button'
import { CurrencyListInput } from 'components/CurrencyInputPanel'
import {
  StepFormContainer,
  StepFormBox,
  FormHeaderBox,
  FormHeaderTitle,
  FormHeaderSubtitle,
  ButtonsContainer,
} from './style'
import useDoricBridge from 'hooks/bridge/useDoricBridge'
import { useNetworkState } from 'store/network/state'
import { useApplicationState } from 'store/application/state'
import { useAccountState } from 'store/account/state'
import SimpleLoader from 'components/SimpleLoader'
import useListenSocketEvent from 'hooks/bridge/useListenSocketEvent'
import { sameAddress } from 'utils/sameAddress'

const NetworkSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  gap: 15px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    font-size: 12px;
  `};
`

const DetailsTable = styled.table`
  width: 100%;
  margin: 15px 0px;

  td {
    padding: 10px;
    border-bottom: 1px dashed ${({ theme }) => theme.text4};
  }
`

const NetworkTokenInputCard = ({
  label,
  network,
  balance,
  isLoadingBalance,
  tokenBalance,
  showTokenBalance,
  isLoadingToken,
  tokensList,
  selectedCurrency,
  changeSelectedCurrency,
  value,
  onUserInput,
  hideInput,
  onClickNetworkSelect,
}) => {
  return (
    <div>
      <NetworkSelect>
        <ButtonDropdownGrey
          onClick={onClickNetworkSelect}
          style={{ height: '30px' }}
        >
          {label}:&nbsp; <b>{network}</b>
        </ButtonDropdownGrey>
        {isLoadingBalance ? (
          <SimpleLoader />
        ) : (
          <div style={{ textAlign: 'right' }}>
            Balance: <b>{balance}</b>
            {showTokenBalance && (
              <div style={{ paddingTop: '10px' }}>
                {isLoadingToken ? <SimpleLoader /> : <b>{tokenBalance}</b>}
              </div>
            )}
          </div>
        )}
      </NetworkSelect>
      {!hideInput && (
        <CurrencyListInput
          tokensList={tokensList}
          value={value}
          currency={selectedCurrency}
          error={true}
          success={false}
          onSelectCurrency={token => changeSelectedCurrency(token)}
          onUserInput={onUserInput}
        />
      )}
    </div>
  )
}

const Dashboard = () => {
  const {
    bridgeOptions,
    selectedBridgeOption,
    handleChangeSelectedBridgeOption,
    tokenBridgeOptions,
    balances,
    isBridgeActive,
    selectedCurrency,
    changeSelectedCurrency,
    form,
    changeAmount,
    bridgeByNative,
    bridgeByToken,
    txDetails,
    handleApproveTokenAllowance,
    showApproveButton,
    canBridgeToken,
    invalidAmount,
    isLoadingBridgeOptions,
    isLoadingBalance,
    isLoadingToken,
    fetchBalances,
    totalWithFee,
    handleSetMaxAmount,
  } = useDoricBridge()
  const { selectedNetwork, setSelectedNetwork } = useNetworkState()
  const { openPopup, closePopup } = useApplicationState()
  const { address } = useAccountState()

  const callback = useCallback(
    ({ transaction }) => {
      if (sameAddress(address, transaction.from_address)) fetchBalances()
    },
    [fetchBalances, address],
  )

  useListenSocketEvent({
    event: 'TRANSACTION_CREATED',
    callback,
    active: !!address,
  })

  useListenSocketEvent({
    event: 'TRANSACTION_UPDATED',
    callback,
    active: !!address,
  })

  const onClickNetworkSelect = (direction = 'from') => {
    const options = bridgeOptions.reduce((acc, bridgeOption) => {
      if (
        direction === 'from' &&
        bridgeOption.from_network.name !== selectedNetwork &&
        acc.findIndex(
          o => o.from_network.name === bridgeOption.from_network.name,
        ) === -1
      ) {
        acc.push(bridgeOption)
      } else if (
        direction === 'to' &&
        bridgeOption.from_network.name === selectedNetwork
      ) {
        acc.push(bridgeOption)
      }

      return acc
    }, [])

    const onSelect =
      direction === 'from'
        ? item => setSelectedNetwork(item.from_network.name)
        : item => {
            handleChangeSelectedBridgeOption(item)
            closePopup('CHANGE_NETWORK')
          }

    openPopup('CHANGE_NETWORK', () => (
      <div style={{ width: '250px' }}>
        {options.map(item => {
          const network =
            direction === 'from' ? item.from_network : item.to_network

          return (
            <ButtonOutlined
              key={network.name}
              borderRadius={'5px'}
              onClick={() => onSelect(item)}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '15px',
                width: '100%',
                fontWeight: 'bold',
              }}
            >
              <img
                src={network.icon}
                alt={network.name}
                style={{ width: '25px', borderRadius: '50%' }}
              />
              {network.name}
            </ButtonOutlined>
          )
        })}
      </div>
    ))
  }

  const isLoading = isLoadingBridgeOptions || isLoadingBalance || isLoadingToken
  const blockButton = invalidAmount || isLoading

  return (
    <StepFormContainer>
      <FormHeaderTitle>Doric Bridge</FormHeaderTitle>
      <FormHeaderSubtitle>
        Transfer your tokens between Doric and Ethereum networks.
      </FormHeaderSubtitle>
      {isLoadingBridgeOptions && <SimpleLoader />}
      {!isLoadingBridgeOptions && selectedBridgeOption?.from_network && (
        <div>
          <StepFormBox>
            <FormHeaderBox>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                  margin: '0px',
                }}
              >
                <div>
                  <NetworkTokenInputCard
                    label="From"
                    network={selectedBridgeOption?.from_network.name}
                    balance={`${balances.from.native} ${selectedBridgeOption?.from_network?.symbol}`}
                    isLoadingBalance={isLoadingBalance}
                    tokenBalance={`${balances.from.token} ${selectedCurrency?.symbol}`}
                    showTokenBalance={[
                      'tokenToNative',
                      'tokenToToken',
                    ].includes(selectedCurrency?.bridge_type)}
                    isLoadingToken={isLoadingToken}
                    tokensList={tokenBridgeOptions}
                    selectedCurrency={selectedCurrency}
                    changeSelectedCurrency={changeSelectedCurrency}
                    value={form.amount}
                    onUserInput={value => changeAmount(value)}
                    onClickNetworkSelect={() => onClickNetworkSelect('from')}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: 10,
                    }}
                  >
                    {selectedCurrency?.bridge_type === 'nativeToToken' && (
                      <div
                        style={{
                          textAlign: 'right',
                          fontSize: '11px',
                        }}
                      >
                        Minimum:{' '}
                        {ethers.utils.formatEther(txDetails.minBridgeNative)}{' '}
                        {selectedBridgeOption.from_network.symbol}
                      </div>
                    )}
                    {selectedCurrency?.bridge_type !== 'nativeToToken' && (
                      <div
                        style={{
                          textAlign: 'right',
                          fontSize: '11px',
                        }}
                      >
                        Minimum:{' '}
                        {ethers.utils.formatUnits(
                          txDetails.minBridgeToken,
                          selectedCurrency.decimals,
                        )}{' '}
                        {selectedCurrency.symbol}
                      </div>
                    )}
                    <ButtonGray
                      style={{
                        border: 'none',
                        fontSize: 14,
                        padding: 5,
                        borderRadius: 8,
                      }}
                      onClick={handleSetMaxAmount}
                    >
                      Max
                    </ButtonGray>
                  </div>
                </div>
                <NetworkTokenInputCard
                  label="To"
                  network={selectedBridgeOption?.to_network.name}
                  balance={`${balances.to.native} ${selectedBridgeOption?.to_network?.symbol}`}
                  isLoadingBalance={isLoadingBalance}
                  tokenBalance={`${balances.to.token} ${selectedCurrency?.symbol}`}
                  showTokenBalance={['nativeToToken', 'tokenToToken'].includes(
                    selectedCurrency?.bridge_type,
                  )}
                  selectedCurrency={selectedCurrency}
                  changeSelectedCurrency={changeSelectedCurrency}
                  onClickNetworkSelect={() => onClickNetworkSelect('to')}
                  hideInput
                />
              </div>
            </FormHeaderBox>
          </StepFormBox>
          <div>
            <DetailsTable>
              <tbody>
                <tr>
                  <td>Gas fee</td>
                  <td
                    style={{ textAlign: 'right', fontWeight: '500' }}
                  >{`${ethers.utils.formatEther(txDetails.fee.toString())} ${
                    selectedBridgeOption.from_network.symbol
                  }`}</td>
                </tr>
                {selectedCurrency?.bridge_type !== 'nativeToToken' && (
                  <tr>
                    <td>Token fee</td>
                    <td
                      style={{ textAlign: 'right', fontWeight: '500' }}
                    >{`${ethers.utils.formatUnits(
                      txDetails.tokenFee.toString(),
                      selectedCurrency.decimals,
                    )} ${selectedCurrency.symbol}`}</td>
                  </tr>
                )}
                <tr>
                  <td>Total</td>
                  <td
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    {totalWithFee
                      ? `${totalWithFee} ${selectedCurrency?.symbol}`
                      : '--'}
                  </td>
                </tr>
                <tr>
                  <td>You will receive</td>
                  <td
                    style={{
                      textAlign: 'right',
                      fontWeight: 'bold',
                    }}
                  >
                    {form.amount
                      ? `${form.amount} ${selectedCurrency?.symbol}`
                      : '--'}
                  </td>
                </tr>
              </tbody>
            </DetailsTable>
            {isBridgeActive && (
              <ButtonsContainer>
                {selectedCurrency?.bridge_type === 'nativeToToken' && (
                  <Button
                    text={`Move funds to ${selectedBridgeOption.to_network.name}`}
                    buttonStyle={blockButton ? 'disabled' : 'highlighted'}
                    onClick={bridgeByNative}
                    disabled={blockButton}
                  />
                )}

                {showApproveButton && (
                  <Button
                    text="Approve"
                    onClick={handleApproveTokenAllowance}
                    buttonStyle={blockButton ? 'disabled' : 'highlighted'}
                    disabled={blockButton}
                  />
                )}
                {canBridgeToken && (
                  <Button
                    text={`Move funds to ${selectedBridgeOption.to_network.name}`}
                    buttonStyle={blockButton ? 'disabled' : 'highlighted'}
                    onClick={bridgeByToken}
                    disabled={blockButton}
                  />
                )}
              </ButtonsContainer>
            )}
          </div>
        </div>
      )}
    </StepFormContainer>
  )
}

export default Dashboard
