import React from 'react'
import { PulseLoader } from 'react-spinners'
import { useUserState } from 'store/user/state'
import { theme } from 'theme'

const SimpleLoader = ({ color, size = 7 }) => {
  const { isDarkMode } = useUserState()
  const { text1 } = theme(isDarkMode)

  return <PulseLoader color={color || text1} size={size} />
}

export default SimpleLoader
