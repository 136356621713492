import { useEffect } from 'react'
import { socket } from 'api/websocket'

export default function WebSocketProvider() {
  useEffect(() => {
    socket.connect()
  }, [])

  return null
}
