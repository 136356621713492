import React from 'react'

const Footer = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div>
        <h6>Doric Bridge &copy; 2024</h6>
      </div>
    </div>
  )
}

export default Footer
