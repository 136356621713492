export const LOADING_DETAILS = 'LOADING_DETAILS'
export const ACCOUNT_DETAILS = 'ACCOUNT_DETAILS'
export const TRANSACTION_DETAILS = 'TRANSACTION_DETAILS'
export const CONFIRM_TRANSACTION = 'CONFIRM_TRANSACTION'
export const SEED_DETAILS = 'SEED_DETAILS'
export const SET_SEED_WORDS = 'SET_SEED_WORDS'
export const RESTORE_BY_KEY_STORE = 'RESTORE_BY_KEY_STORE'
export const RESTORE_BY_PK = 'RESTORE_BY_PK'
export const CURRENCY_LIST_DETAILS = 'CURRENCY_LIST_DETAILS'
export const HISTORY_ACTION_CONFIRMATION = 'HISTORY_ACTION_CONFIRMATION'
