import React from 'react'
import Background from '../../assets/images/background.png'
import { ButtonConnectWallet } from 'components/Button'
import styled from 'styled-components'
import Loader from 'components/Loader'
import { MEDIA_WIDTHS, TYPE } from 'theme'
import { useApplicationState } from 'store/application/state'
import { useNetworkState } from 'store/network/state'
import { useAccountState } from 'store/account/state'
import { LOADING_DETAILS } from 'store/application/types'
import {
  getMetaMaskParams,
  getProvider,
  isDoricNetworkChainId,
} from 'constants/provider'
import SimpleLoader from 'components/SimpleLoader'
import { useHistory } from 'react-router-dom'
import DoricLogoBlack from 'components/DoricLogo/DoricLogoBlack'

const provider = getProvider()

const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#E1E7E4'};

  @media screen and (max-width: ${MEDIA_WIDTHS.upToLarge}px) {
    justify-content: center;
    align-items: center;
  }
`

const BackgroundSection = styled.img`
  height: 100%;
  max-width: max-content;
  display: none;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToLarge}px) {
    display: inline-block;
  }
`

const WalletSection = styled.div`
  display: flex;
  gap: 45px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 500px;
  flex-direction: column;

  @media screen and (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    max-width: 300px;
  }
`

export const Title = styled.div`
  font-size: 35px;
  font-weight: normal;
  width: 100%;
  max-width: 400px;
  color: ${({ color }) => color ?? '#1E1E1E'};

  @media screen and (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    max-width: 300px;
    font-size: 30px;
  }
`

export const Logo = styled.div`
  display: none;

  @media screen and (max-width: ${MEDIA_WIDTHS.upToLarge}px) {
    display: inline-block;
  }
`

const ConnectWallet = () => {
  const { openPopup } = useApplicationState()
  const { selectedNetwork } = useNetworkState()

  const {
    setLogged,
    setAddress,
    startLoading,
    stopLoading,
    isLoading,
    isLogged,
  } = useAccountState()

  const history = useHistory()

  const showNotification = ({ content }) => {
    openPopup(LOADING_DETAILS, () => <div>{content}</div>)
  }

  async function setSigner() {
    const signer = provider.getSigner()
    const newAddress = await signer.getAddress()
    if (newAddress) {
      setAddress(newAddress)
      setLogged(true)
      stopLoading()
    }
    history.push('/')
  }

  function noMetaMask() {
    showNotification({
      content: (
        <center style={{ paddingTop: '15px' }}>
          <TYPE.largeHeader>No MetaMask detected</TYPE.largeHeader>
          <p>Please install MetaMask wallet</p>
          <h5>How to install MetaMask on your device?</h5>
          <a
            href="https://metamask.io/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://metamask.io/download
          </a>
        </center>
      ),
    })
  }

  async function requestMetaMask() {
    const { ethereum } = window

    if (ethereum) {
      try {
        await ethereum.enable()

        const currentChainId = await provider.send('eth_chainId')
        if (!isDoricNetworkChainId(currentChainId)) {
          showNotification({
            content: (
              <center style={{ paddingTop: '15px' }}>
                <TYPE.largeHeader>Wrong network detected</TYPE.largeHeader>
                <p>Please switch to the Doric network</p>
                <SimpleLoader />
              </center>
            ),
          })
        }

        const metamaskParams = getMetaMaskParams(selectedNetwork)
        const { chainId: SELECTED_CHAIN_ID } = metamaskParams[0]

        await provider.send('eth_requestAccounts', [])

        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: SELECTED_CHAIN_ID }],
          })
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: metamaskParams,
              })
            } catch (addError) {
              console.log(addError)
            }
          }
        }

        await setSigner()
      } catch (error) {
        ethereum.on('accountsChanged', accounts => {
          if (accounts.length > 0) {
            requestMetaMask()
          }
        })
        console.log('Waiting MetaMask connection...')
      }
    } else {
      noMetaMask()
    }
  }

  async function connectMetamask() {
    startLoading()

    if (provider) {
      await requestMetaMask()
    } else {
      noMetaMask()
      stopLoading()
    }
  }

  if (isLogged) {
    return null
  }

  return (
    <MenuWrapper>
      <BackgroundSection src={Background} />

      <WalletSection>
        <Logo>
          <DoricLogoBlack />
        </Logo>

        <Title>Choose how you want to connect</Title>

        <ButtonWrapper>
          <ButtonConnectWallet
            isLoading={isLoading}
            disabled={isLoading}
            onClick={connectMetamask}
          >
            Metamask
            {isLoading && <Loader size="24px" />}
          </ButtonConnectWallet>
          <ButtonConnectWallet disabled>Ledger</ButtonConnectWallet>
          <ButtonConnectWallet disabled>Trezor</ButtonConnectWallet>
        </ButtonWrapper>
      </WalletSection>
    </MenuWrapper>
  )
}

export default ConnectWallet
