import React, { Suspense, useEffect } from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { ethers } from 'ethers'
import ModalProvider from 'components/ModalProvider'
import { routes } from './routes'
import ProtectedRoute from 'components/ProtectedRoute'
import { useAccountState } from 'store/account/state'
import { getProvider } from 'constants/provider'

const provider = getProvider()

const AppWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  padding-top: 55px;
`

function App() {
  const {
    isLogged,
    address,
    setBalance,
    setAddress,
    setLogged,
  } = useAccountState()

  async function updateBalance() {
    const newBalance = await provider.getBalance(address)
    setBalance(ethers.utils.formatEther(newBalance))
  }

  useEffect(() => {
    if (address && isLogged) {
      updateBalance()

      provider.on('block', () => {
        updateBalance()
      })
    }
    // eslint-disable-next-line
  }, [address, isLogged])

  async function setSigner() {
    const signer = provider.getSigner()
    const newAddress = await signer.getAddress()

    setAddress(newAddress)
    setLogged(true)
  }

  useEffect(() => {
    setSigner()

    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload()
      })
      window.ethereum.on('accountsChanged', () => {
        window.location.reload()
      })
    }
  }, [])

  return (
    <Suspense fallback={null}>
      <Router>
        <AppWrapper>
          <ModalProvider />

          <Switch>
            {routes.map(({ path, component, authRequired, exact }) => {
              return (
                <ProtectedRoute
                  key={path ?? 'notFound'}
                  path={path}
                  component={component}
                  authRequired={authRequired}
                  exact={exact}
                />
              )
            })}
          </Switch>
        </AppWrapper>
      </Router>
    </Suspense>
  )
}

export default App
