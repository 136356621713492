import apiBridge from 'api'

export const getBridgeStatus = () => {
  return apiBridge.get('/api/public')
}

export const getBridgeOptions = () => {
  return apiBridge.get('/api/public/bridge-options')
}

export const paginateTransactions = (limit, offset, from_address) => {
  return apiBridge.post('/api/public/transactions/paginate', {
    limit,
    offset,
    from_address,
  })
}
