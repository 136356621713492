import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useQuery } from '@tanstack/react-query'
import { AutoColumn } from 'components/Column'
import { ShadowCard } from 'components/Card'
import SimpleLoader from 'components/SimpleLoader'
import { SimpleGrid } from 'pages/Template/styles'
import { TYPE } from 'theme'
import { paginateTransactions } from 'api/public'
import { useAccountState } from 'store/account/state'
import { parseENSAddress } from 'utils/parseENSAddress'
import TxStatusText from 'components/TxStatusText'
import useListenSocketEvent from 'hooks/bridge/useListenSocketEvent'
import { useWindowSize } from 'hooks/useWindowSize'
import { sameAddress } from 'utils/sameAddress'

const CardSection = styled(AutoColumn)`
  grid-template-columns: 1fr;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: auto;
    grid-template-rows: auto;
  `};
`

const Table = styled.table`
  padding: 15px;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 6px;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 5px;
  }

  td .badge-container {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
  }

  td .badge {
    display: block;
    font-size: 11px;
    padding: 3px;
    background-color: ${({ theme }) => theme.bg4};
    border-radius: 6px;
    white-space: nowrap;
  }

  td a {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.blue1};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.text1};
    }
  }
`

const PaginationButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  outline: none;
  border-radius: 6px;
  transition: ease-in-out 0.2s;

  &:disabled {
    color: ${({ theme }) => theme.text4};
    cursor: not-allowed;
  }
`

function explorerTxLink(explorer_url, tx_hash) {
  return `${explorer_url}/tx/${tx_hash}`
}

const Pagination = ({ page, rowsPerPage, setPage, count }) => {
  const goNext = () => {
    setPage(page + 1)
  }

  const goPrev = () => {
    setPage(page - 1)
  }

  const lastPage = count > 0 ? Math.ceil(count / rowsPerPage) : 0

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '15px',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <TYPE.body>
        Page {page + 1} of {lastPage}
      </TYPE.body>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <PaginationButton
          onClick={e => goPrev(e)}
          disabled={page === 0}
          style={{
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
        >
          {'<'}
        </PaginationButton>
        <PaginationButton
          onClick={e => goNext(e)}
          disabled={page === lastPage - 1}
          style={{
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
        >
          {'>'}
        </PaginationButton>
      </div>
    </div>
  )
}

const HistoryPage = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage] = useState(10)
  const { address } = useAccountState()
  const { height } = useWindowSize()

  const tableHeight = height - 250

  const { data, isLoading, refetch } = useQuery(
    ['transactions', page, rowsPerPage, address],
    async () => {
      const { data } = await paginateTransactions(
        rowsPerPage,
        page * rowsPerPage,
        address,
      )
      return data
    },
    { enabled: !!address },
  )

  useListenSocketEvent({
    event: 'TRANSACTION_CREATED',
    callback: ({ transaction }) => {
      if (sameAddress(address, transaction.from_address)) refetch()
    },
  })

  useListenSocketEvent({
    event: 'TRANSACTION_UPDATED',
    callback: ({ transaction }) => {
      if (sameAddress(address, transaction.from_address)) refetch()
    },
  })

  const { t } = useTranslation()

  return (
    <SimpleGrid>
      <CardSection>
        <ShadowCard>
          <TYPE.largeHeader style={{ padding: '15px' }}>
            {t(`Transactions history`)}
          </TYPE.largeHeader>
          <div
            style={{
              padding: '15px',
              width: '100%',
              maxHeight: tableHeight,
              overflowX: 'auto',
            }}
          >
            <Table maxHeight={tableHeight}>
              <thead>
                <tr>
                  <th>{t('From network')}</th>
                  <th>{t('To network')}</th>
                  <th>{t('Amount')}</th>
                  <th>{t('Tx hash')}</th>
                  <th>{t('Status')}</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan="5" align="center">
                      <SimpleLoader />
                    </td>
                  </tr>
                )}
                {!data?.transactions?.length && !isLoading && (
                  <tr>
                    <td colSpan="5" align="center">
                      {t('No transactions found...')}
                    </td>
                  </tr>
                )}
                {data?.transactions?.length > 0 &&
                  data.transactions.map(
                    ({
                      id,
                      amount,
                      symbol,
                      to_tx_hash,
                      status,
                      bridgeConfig,
                    }) => {
                      return (
                        <tr key={id}>
                          <td>{bridgeConfig?.fromNetwork?.name}</td>
                          <td>{bridgeConfig?.toNetwork?.name}</td>
                          <td>
                            {amount} {symbol}
                          </td>
                          <td>
                            <a
                              href={explorerTxLink(
                                bridgeConfig?.toNetwork?.explorer_url,
                                to_tx_hash,
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {parseENSAddress(to_tx_hash)}
                            </a>
                          </td>
                          <td>
                            <TxStatusText status={status} />
                          </td>
                        </tr>
                      )
                    },
                  )}
              </tbody>
            </Table>
          </div>
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            count={data?.count}
          />
        </ShadowCard>
      </CardSection>
    </SimpleGrid>
  )
}

export default HistoryPage
