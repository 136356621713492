import { useEffect, useMemo, useState } from 'react'
import { ethers } from 'ethers'
import { useAccountState } from 'store/account/state'
import { useNetworkState } from 'store/network/state'
import useLoading from 'hooks/useLoading'
import { getBridgeStatus, getBridgeOptions } from 'api/public'
import useBridgeProvidersWithContracts from './useBridgeProvidersWithContracts'
import useTxMessages from './useTxMessages'
import getAlllBridgeCurrencyOptions from 'utils/getAlllBridgeCurrencyOptions'

const GAS_LIMIT = 100000

function getChanIdHex(chain_id) {
  return `0x${chain_id.toString(16)}`
}

export const getMetaMaskParams = network => {
  return [
    {
      chainId: getChanIdHex(network.chain_id),
      chainName: network.name,
      rpcUrls: [network.url],
      nativeCurrency: network.symbol,
    },
  ]
}

export const parseAmount = value => {
  if (!value || value === 0) return ''

  const [int, decimals] = `${value}`.split('.')
  if (!decimals) return value

  return `${int}.${decimals.slice(0, 8)}`
}

export default function useDoricBridge() {
  const {
    startLoading,
    stopLoading,
    setAddress,
    setLogged,
    address,
  } = useAccountState()
  const {
    handleCloseNotification,
    showConnectingWalletMessage,
    showApprovalMessage,
    showBlockConfirmationsMessage,
    showAllowanceApprovedMessage,
    showBridgeTxSentMessage,
    showErrorMessage,
    showApiErrorMessage,
    showNoMetaMaskMessage,
    showWrongNetworkMessage,
  } = useTxMessages()
  const { selectedNetwork } = useNetworkState()
  const [bridgeOptions, setBridgeOptions] = useState([])
  const [selectedBridgeOption, setSelectedBridgeOption] = useState({})
  const [selectedCurrency, setSelectedCurrency] = useState(bridgeOptions[0])
  const {
    metamaskProvider,
    signedBridgeContract,
    approveTokenAllowance,
    SEND_TOKEN_TYPE_BRIDGE,
    balances,
    txDetails,
    fetchTxDetails,
    fetchBalances,
    isLoadingBalance,
    isLoadingToken,
  } = useBridgeProvidersWithContracts({
    address,
    selectedBridgeOption,
    selectedCurrency,
  })

  const [isBridgeActive, setIsBridgeActive] = useState(false)

  const [form, setForm] = useState({ amount: 0 })
  const {
    isLoading: isLoadingBridgeOptions,
    setLoading: setLoadingBridgeOptions,
  } = useLoading()

  const changeSelectedCurrency = token => {
    setSelectedCurrency(token)
  }

  const changeAmount = value => {
    setForm({
      ...form,
      amount: parseAmount(value),
    })
  }

  const resetForm = () => {
    setForm({ amount: 0 })
  }

  async function setSigner() {
    const signer = metamaskProvider.getSigner()
    const newAddress = await signer.getAddress()

    setAddress(newAddress)
    setLogged(true)
  }

  function isSupportedChainId(bridgeOptions, selectedChainId) {
    if (!bridgeOptions?.length) return false
    return bridgeOptions
      ?.map(({ from_network }) => getChanIdHex(from_network.chain_id))
      .map(chainIdHex => chainIdHex.toUpperCase())
      .includes(selectedChainId.toUpperCase())
  }

  function checkConnectedNetwork(bridgeOptions, currentChainId) {
    if (!isSupportedChainId(bridgeOptions, currentChainId)) {
      showWrongNetworkMessage({
        onRetry: () => initializeNetworks(),
      })
      return false
    }

    return true
  }

  async function requestMetamask(bridgeOptions, selectedOption) {
    const { ethereum } = window

    showConnectingWalletMessage()

    if (ethereum) {
      try {
        await ethereum.enable()

        const currentChainId = await metamaskProvider.send('eth_chainId')

        const isSupportedNetwork = checkConnectedNetwork(
          bridgeOptions,
          currentChainId,
        )

        const metamaskParams = getMetaMaskParams(selectedOption.from_network)
        const { chainId: SELECTED_CHAIN_ID } = metamaskParams[0]

        await metamaskProvider.send('eth_requestAccounts', [])

        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: SELECTED_CHAIN_ID }],
          })
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: metamaskParams,
              })
            } catch (addError) {
              console.log(addError)
            }
          }
        }

        await setSigner()
        if (isSupportedNetwork) handleCloseNotification()
      } catch (error) {
        showErrorMessage(error)
        console.error(error)
      }
    } else {
      showNoMetaMaskMessage()
    }
  }

  async function connectMetamask(bridgeOptions, selectedOption) {
    startLoading()

    if (metamaskProvider) {
      await requestMetamask(bridgeOptions, selectedOption)
    } else {
      showNoMetaMaskMessage()
    }

    stopLoading()
  }
  async function initializeNetworks() {
    setLoadingBridgeOptions(true)

    try {
      const { data: options } = await getBridgeOptions()

      setBridgeOptions(options)

      const selectedOption =
        options.find(option => option.from_network.name === selectedNetwork) ||
        options[0]
      setSelectedBridgeOption(selectedOption)
      setSelectedCurrency(selectedOption.tokens.bridgeNativeToken)

      connectMetamask(options, selectedOption)
    } catch (error) {
      showApiErrorMessage({
        message:
          "Couldn't load bridge options, please try again later. The API may be temporarily unavailable due to maintenance. Thank you!",
      })
    }

    setLoadingBridgeOptions(false)
  }

  function handleChangeSelectedBridgeOption(bridgeOption) {
    const options = getAlllBridgeCurrencyOptions(bridgeOption)
    const reselectOption =
      options.find(({ symbol }) => symbol === selectedCurrency?.symbol) ||
      options[0]

    setSelectedBridgeOption(bridgeOption)
    setSelectedCurrency(reselectOption)
  }

  async function bridgeByNative() {
    const amount = ethers.utils.parseUnits(
      form.amount,
      selectedCurrency.decimals,
    )

    if (txDetails.minBridgeNative.gt(amount)) {
      return showErrorMessage({
        message: `Amount must be greater than minimum (${txDetails.minBridgeNative.toString()} ${
          selectedCurrency.symbol
        }).`,
      })
    }

    showApprovalMessage()

    const amountWithFee = amount.add(txDetails.fee)
    const signer = metamaskProvider.getSigner()
    const nonce = await signer.getTransactionCount()

    try {
      const tx = await signedBridgeContract.bridgeByNative(
        address,
        ethers.utils.parseUnits(form.amount, selectedCurrency.decimals),
        {
          value: amountWithFee,
          gasLimit: GAS_LIMIT,
          nonce,
        },
      )

      showBlockConfirmationsMessage()

      await tx.wait()

      showBridgeTxSentMessage(selectedBridgeOption.to_network.name)
      fetchBalances()
      resetForm()
    } catch (err) {
      console.error(err)
      showErrorMessage(err)
    }
  }

  async function bridgeByToken() {
    showApprovalMessage()

    const signer = metamaskProvider.getSigner()
    const nonce = await signer.getTransactionCount()

    try {
      const tx = await signedBridgeContract.bridgeByToken(
        selectedCurrency.from_network_token_address,
        address,
        ethers.utils.parseUnits(form.amount, selectedCurrency.decimals),
        {
          value: txDetails.fee,
          gasLimit: GAS_LIMIT,
          nonce,
        },
      )

      showBlockConfirmationsMessage()

      await tx.wait()

      showBridgeTxSentMessage(selectedBridgeOption.to_network.name)

      resetForm()
      fetchBalances()
      fetchTxDetails()
    } catch (err) {
      console.error(err)
      showErrorMessage(err)
    }
  }

  function handleApproveTokenAllowance() {
    approveTokenAllowance(form.amount, {
      onStart: () => showApprovalMessage(),
      onSubmit: () => showBlockConfirmationsMessage(),
      onSuccess: () => showAllowanceApprovedMessage(),
      onError: err => showErrorMessage(err),
    })
  }

  function handleSetMaxAmount() {
    if (!selectedCurrency?.decimals) return

    const balance = SEND_TOKEN_TYPE_BRIDGE
      ? balances.from.token
      : balances.from.native
    const balanceMinusFee = ethers.utils
      .parseUnits(balance || '0', selectedCurrency.decimals)
      .sub(fee)

    if (parseFloat(balanceMinusFee) > 0) {
      changeAmount(
        ethers.utils.formatUnits(balanceMinusFee, selectedCurrency.decimals),
      )
    }
  }

  useEffect(() => {
    try {
      initializeNetworks()
    } catch (err) {
      console.error(err)
      stopLoading()
    }

    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload()
      })
      window.ethereum.on('accountsChanged', () => {
        window.location.reload()
      })
    }

    try {
      getBridgeStatus().then(res => {
        if (res?.data?.active === true) {
          setIsBridgeActive(true)
        } else {
          console.error('Bridge API is not active')
          setIsBridgeActive(false)
        }
      })
    } catch (err) {
      setIsBridgeActive(false)
      console.error(err)
    }
  }, [])

  const fee = SEND_TOKEN_TYPE_BRIDGE
    ? txDetails?.tokenFee || '0'
    : txDetails?.fee || '0'

  const amountWithFee = ethers.utils
    .parseUnits(form?.amount || '0', selectedCurrency?.decimals)
    ?.add(fee)

  const hasAllowance =
    parseFloat(form.amount) > 0 &&
    txDetails.allowance?.gte &&
    txDetails.allowance?.gte(amountWithFee)
  const canBridgeToken = SEND_TOKEN_TYPE_BRIDGE && hasAllowance
  const showApproveButton = SEND_TOKEN_TYPE_BRIDGE && !hasAllowance
  const invalidMinNativeAmount =
    form.amount > 0 &&
    selectedCurrency?.bridge_type === 'nativeToToken' &&
    txDetails.minBridgeNative?.gt &&
    txDetails.minBridgeNative?.gt(
      ethers.utils.parseUnits(form.amount, selectedCurrency.decimals),
    )
  const invalidMinTokenAmount =
    form.amount > 0 &&
    ['tokenToNative', 'tokenToToken'].includes(selectedCurrency?.bridge_type) &&
    txDetails.minBridgeToken?.gt &&
    txDetails.minBridgeToken?.gt(
      ethers.utils.parseUnits(form.amount, selectedCurrency.decimals),
    )
  const invalidAmount =
    form.amount <= 0 || invalidMinNativeAmount || invalidMinTokenAmount

  const tokenBridgeOptions = useMemo(() => {
    return getAlllBridgeCurrencyOptions(selectedBridgeOption)
  }, [selectedBridgeOption])

  return {
    bridgeOptions,
    selectedBridgeOption,
    handleChangeSelectedBridgeOption,
    tokenBridgeOptions,
    balances,
    isBridgeActive,
    selectedCurrency,
    changeSelectedCurrency,
    form,
    changeAmount,
    bridgeByNative,
    bridgeByToken,
    txDetails,
    handleApproveTokenAllowance,
    showApproveButton,
    hasAllowance,
    canBridgeToken,
    invalidAmount,
    isLoadingBridgeOptions,
    isLoadingBalance,
    isLoadingToken,
    fetchBalances,
    totalWithFee: ethers.utils.formatUnits(
      amountWithFee || '0',
      selectedCurrency?.decimals,
    ),
    handleSetMaxAmount,
  }
}
