import React from 'react'
import { isMobile } from 'react-device-detect'
import { animated } from 'react-spring'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { transparentize } from 'polished'
import styled, { css } from 'styled-components'
import Close from '../SVG/CloseIcon'
import { device } from 'utils/devices'

const AnimatedDialogOverlay = animated(DialogOverlay)
const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  &[data-reach-dialog-overlay] {
    z-index: 2;
    background-color: transparent;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(3px);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    @media ${device.laptop} {
      padding: 0;
    }
  }
`

const AnimatedDialogContent = animated(DialogContent)

const StyledDialogContent = styled(
  // eslint-disable-next-line
  ({ minHeight, maxHeight, mobile, isOpen, ...rest }) => (
    <AnimatedDialogContent {...rest} />
  ),
).attrs({
  'aria-label': 'dialog',
})`
  overflow-y: auto;

  &[data-reach-dialog-content] {
    margin: 0 0 2rem 0;
    border-radius: 25px;
    border: 1px solid #b8b8b8;
    background: var(
      --gray-white-fill,
      linear-gradient(225deg, #f0f2f8 0%, #fafafa 30.21%, #fafafa 100%)
    );

    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08) inset;
    padding: 35px 45px;
    overflow-y: auto;
    overflow-x: hidden;

    align-self: ${({ mobile }) => (mobile ? 'flex-end' : 'center')};

    max-width: 640px;
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-height: ${maxHeight}vh;
      `}
    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${minHeight}vh;
      `}
    display: flex;
    border-radius: 20px;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  z-index: 100;
`

export default function Modal({
  onDismiss,
  minHeight = false,
  maxHeight = 90,
  initialFocusRef,
  children,
}) {
  return (
    <StyledDialogOverlay
      onDismiss={onDismiss}
      initialFocusRef={initialFocusRef}
      unstable_lockFocusAcrossFrames={false}
    >
      <div style={{ position: 'relative' }}>
        <CloseIcon onClick={onDismiss}>
          <CloseColor height="15" width="15" />
        </CloseIcon>
        <StyledDialogContent
          aria-label="dialog content"
          minHeight={minHeight}
          maxHeight={maxHeight}
          mobile={isMobile}
        >
          {children}
        </StyledDialogContent>
      </div>
    </StyledDialogOverlay>
  )
}
