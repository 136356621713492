import { useEffect, useMemo, useState } from 'react'
import { ethers } from 'ethers'
import { getProvider } from 'constants/provider'
import { BRIDGE_CONTRACT_ABI } from 'constants/abi/bridgeContractABI'
import { ERC20_CONTRACT_ABI } from 'constants/abi/erc20ContractABI'
import useLoading from 'hooks/useLoading'

function getChanIdHex(chain_id) {
  return `0x${chain_id.toString(16)}`
}

export const getMetaMaskParams = network => {
  return [
    {
      chainId: getChanIdHex(network.chain_id),
      chainName: network.name,
      rpcUrls: [network.url],
      nativeCurrency: network.symbol,
    },
  ]
}

export default function useBridgeProvidersWithContracts({
  address,
  selectedBridgeOption,
  selectedCurrency,
}) {
  // providers
  const metamaskProvider = getProvider()
  const [fromNetworkProvider, toNetworkProvider] = useMemo(() => {
    if (
      !selectedBridgeOption?.from_network?.chain_id ||
      !selectedBridgeOption?.to_network?.chain_id
    )
      return []

    const provider1 = new ethers.providers.JsonRpcProvider(
      selectedBridgeOption.from_network.url,
      {
        chainId: selectedBridgeOption.from_network.chain_id,
      },
    )
    const provider2 = new ethers.providers.JsonRpcProvider(
      selectedBridgeOption.to_network.url,
      {
        chainId: selectedBridgeOption.to_network.chain_id,
      },
    )

    return [provider1, provider2]
  }, [selectedBridgeOption?.id])

  // contracts
  const signedBridgeContract = useMemo(() => {
    if (
      !selectedBridgeOption?.from_network_bridge_contract ||
      !metamaskProvider
    )
      return

    const signer = metamaskProvider.getSigner()
    return new ethers.Contract(
      selectedBridgeOption.from_network_bridge_contract,
      BRIDGE_CONTRACT_ABI,
      signer,
    )
  }, [metamaskProvider, selectedBridgeOption?.id])

  const fromSignedTokenContract = useMemo(() => {
    if (!selectedCurrency?.from_network_token_address || !metamaskProvider)
      return

    const signer = metamaskProvider.getSigner()
    return new ethers.Contract(
      selectedCurrency.from_network_token_address,
      ERC20_CONTRACT_ABI,
      signer,
    )
  }, [metamaskProvider, selectedCurrency?.from_network_token_address])

  const readWrappedTokenContract = useMemo(() => {
    if (!selectedCurrency?.to_network_token_address || !toNetworkProvider)
      return

    return new ethers.Contract(
      selectedCurrency.to_network_token_address,
      ERC20_CONTRACT_ABI,
      toNetworkProvider,
    )
  }, [toNetworkProvider, selectedCurrency?.to_network_token_address])

  // constants
  const SEND_TOKEN_TYPE_BRIDGE = ['tokenToNative', 'tokenToToken'].includes(
    selectedCurrency?.bridge_type,
  )

  // states
  const [balances, setBalances] = useState({
    from: { native: 0, token: 0 },
    to: { native: 0, token: 0 },
  })
  const [txDetails, setTxDetails] = useState({
    fee: 0,
    tokenFee: 0,
    allowance: 0,
    minBridgeNative: 0,
    minBridgeToken: 0,
  })
  const {
    isLoading: isLoadingBalance,
    setLoading: setLoadingBalance,
  } = useLoading()
  const {
    isLoading: isLoadingToken,
    setLoading: setLoadingToken,
  } = useLoading()

  async function fetchTxDetails() {
    if (!signedBridgeContract?.address) return

    const bridgeGasFee = await signedBridgeContract.bridgeGasFee()

    if (SEND_TOKEN_TYPE_BRIDGE) {
      setTxDetails({
        fee: bridgeGasFee,
        tokenFee: await signedBridgeContract.bridgeableFees(
          fromSignedTokenContract.address,
        ),
        allowance: await fromSignedTokenContract.allowance(
          address,
          signedBridgeContract.address,
        ),
        minBridgeNative: await signedBridgeContract.minBridgeNative(),
        minBridgeToken: await signedBridgeContract.bridgeableMin(
          fromSignedTokenContract.address,
        ),
      })
    } else if (selectedCurrency?.bridge_type === 'nativeToToken') {
      const nativeFee = await signedBridgeContract.bridgeNativeFee()
      const totalFee = bridgeGasFee.add(nativeFee)
      setTxDetails({
        fee: totalFee,
        tokenFee: 0,
        allowance: 0,
        minBridgeNative: await signedBridgeContract.minBridgeNative(),
        minBridgeToken: 0,
      })
    }
  }

  async function approveTokenAllowance(
    amount,
    { onStart, onSubmit, onSuccess, onError },
  ) {
    onStart()

    const signer = metamaskProvider.getSigner()
    const nonce = await signer.getTransactionCount()
    const amountWithFee = txDetails.tokenFee.add(
      ethers.utils.parseUnits(amount, selectedCurrency.decimals),
    )

    try {
      const tx = await fromSignedTokenContract.approve(
        signedBridgeContract.address,
        amountWithFee,
        {
          nonce,
        },
      )

      onSubmit({ tx })

      const receipt = await tx.wait()

      onSuccess({ receipt })

      const newAllowance = await fromSignedTokenContract.allowance(
        address,
        signedBridgeContract.address,
      )

      setTxDetails({
        ...txDetails,
        allowance: newAllowance,
      })
    } catch (err) {
      console.error(err)
      onError(err)
    }
  }

  const fetchBalances = async () => {
    if (!address) return

    try {
      setLoadingBalance(true)
      const balance1 = await fromNetworkProvider?.getBalance(address)
      const balance2 = await toNetworkProvider?.getBalance(address)
      setLoadingBalance(false)

      let fromTokenBalance = 0
      let toTokenBalance = 0

      setLoadingToken(true)
      if (selectedCurrency?.bridge_type === 'tokenToNative') {
        fromTokenBalance = await fromSignedTokenContract?.balanceOf(address)
      } else if (selectedCurrency?.bridge_type === 'nativeToToken') {
        toTokenBalance = await readWrappedTokenContract?.balanceOf(address)
      } else {
        fromTokenBalance = await fromSignedTokenContract?.balanceOf(address)
        toTokenBalance = await readWrappedTokenContract?.balanceOf(address)
      }

      setLoadingToken(false)

      setBalances({
        from: {
          native: ethers.utils.formatEther(balance1 || 0),
          token: ethers.utils.formatUnits(
            fromTokenBalance || 0,
            selectedCurrency?.decimals,
          ),
        },
        to: {
          native: ethers.utils.formatEther(balance2 || 0),
          token: ethers.utils.formatUnits(
            toTokenBalance || 0,
            selectedCurrency?.decimals,
          ),
        },
      })
    } catch (err) {
      setLoadingBalance(false)
      setLoadingToken(false)
      console.error(err)
    }
  }

  useEffect(() => {
    fetchTxDetails()
    fetchBalances()
  }, [
    selectedCurrency,
    signedBridgeContract?.address,
    fromSignedTokenContract?.address,
    signedBridgeContract?.address,
  ])

  return {
    metamaskProvider,
    fromNetworkProvider,
    toNetworkProvider,
    signedBridgeContract,
    fromSignedTokenContract,
    readWrappedTokenContract,
    approveTokenAllowance,
    SEND_TOKEN_TYPE_BRIDGE,
    balances,
    txDetails,
    fetchTxDetails,
    isLoadingBalance,
    isLoadingToken,
    fetchBalances,
  }
}
