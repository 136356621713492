import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 10px;
`

const CurrencyIcon = ({ icon, symbol, ...props }) => {
  return <Image src={icon} title={symbol} alt={symbol} {...props} />
}

export default CurrencyIcon
