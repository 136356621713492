import React from 'react'
import { Route } from 'react-router-dom'
import { useAccountState } from 'store/account/state'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import Header from 'components/Header'
import { device } from 'utils/devices'
import Footer from 'components/Footer'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5rem 1rem;
  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 5rem 1rem 3rem;
  `};
`

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;
  justify-items: center;
  padding: ${({ padding }) => padding};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const HeaderWrapper = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #d0d0d0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.02);
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;

  @media ${device.laptop} {
    background-color: transparent;
    border-bottom: none;
    box-shadow: none;
  }
`

const ProtectedRoute = ({ path, component, authRequired, exact }) => {
  const { isLogged } = useAccountState()
  const show = (authRequired && isLogged) || !authRequired
  const render = show && component

  return (
    <>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <BodyWrapper gap="lg" justify="center">
        <PageWrapper>
          <Route path={path} component={render} exact={exact} />
        </PageWrapper>
      </BodyWrapper>
      <Footer />
    </>
  )
}

export default ProtectedRoute
