import React from 'react'
import { CheckCircle, Coffee } from 'react-feather'
import styled from 'styled-components'
import { TYPE } from 'theme'
import SimpleLoader from 'components/SimpleLoader'
import { ButtonPrimary } from 'components/Button'
import { useApplicationState } from 'store/application/state'
import { LOADING_DETAILS } from 'store/application/types'

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const parseAmount = value => {
  if (!value || value === 0) return ''

  const [int, decimals] = `${value}`.split('.')
  if (!decimals) return value

  return `${int}.${decimals.slice(0, 8)}`
}

export default function useTxMessages() {
  const { openPopup, closePopup } = useApplicationState()

  function showNotification({ content }) {
    openPopup(LOADING_DETAILS, () => <div>{content}</div>)
  }

  function handleCloseNotification() {
    closePopup(LOADING_DETAILS)
  }

  function showNotificationMessage(title, content, loading = true) {
    showNotification({
      content: (
        <center
          style={{
            paddingTop: '15px',
            display: 'flex',
            gap: 15,
            flexDirection: 'column',
          }}
        >
          <TYPE.largeHeader>{title}</TYPE.largeHeader>
          <div>{content}</div>
          {loading && <SimpleLoader />}
        </center>
      ),
    })
  }

  function showConnectingWalletMessage() {
    showNotificationMessage(
      'Connecting wallet',
      'Please check your MetaMask...',
      true,
    )
  }

  function showApprovalMessage() {
    showNotificationMessage(
      'Waiting approval',
      <p>Please review the transaction in your MetaMask</p>,
      true,
    )
  }

  function showAllowanceApprovedMessage() {
    showNotificationMessage(
      'Token contract approved!',
      <p>Now you can bridge your funds</p>,
      false,
    )
  }

  function showBlockConfirmationsMessage() {
    showNotificationMessage(
      <TitleWithIcon>
        Waiting block confirmations
        <Coffee />
      </TitleWithIcon>,
      <p>The transaction is being processed by the network</p>,
    )
  }

  function showBridgeTxSentMessage(network) {
    showNotificationMessage(
      <TitleWithIcon>
        Transaction sent
        <CheckCircle />
      </TitleWithIcon>,
      <p>
        Now you just need to wait <br />
        {`as we bridge your funds to ${network}.`}
      </p>,
      false,
    )
  }

  function showErrorMessage(err, hideReload = false) {
    showNotificationMessage(
      'Error',
      <div>
        <pre style={{ maxWidth: '280px', whiteSpace: 'pre-wrap' }}>
          {err?.message}
        </pre>
        {!hideReload && (
          <ButtonPrimary onClick={() => window.location.reload()}>
            Reload
          </ButtonPrimary>
        )}
      </div>,
      false,
    )
  }

  function showApiErrorMessage(err) {
    showNotificationMessage(
      'Maintenance',
      <div>
        <pre style={{ maxWidth: '280px', whiteSpace: 'pre-wrap' }}>
          {err?.message}
        </pre>
        <ButtonPrimary onClick={() => handleCloseNotification()}>
          OK
        </ButtonPrimary>
      </div>,
      false,
    )
  }

  function showNoMetaMaskMessage() {
    showNotification({
      content: (
        <center style={{ paddingTop: '15px' }}>
          <TYPE.largeHeader>No MetaMask detected</TYPE.largeHeader>
          <p>Please install MetaMask wallet</p>
          <h5>How to install MetaMask on your device?</h5>
          <a
            href="https://metamask.io/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://metamask.io/download
          </a>
        </center>
      ),
    })
  }

  function showWrongNetworkMessage({ onRetry }) {
    showNotificationMessage(
      'Wrong network',
      <div>
        <p>Please switch to the Doric network</p>
        <ButtonPrimary onClick={onRetry}>Retry</ButtonPrimary>
      </div>,
      false,
    )
  }

  return {
    showNotificationMessage,
    handleCloseNotification,
    showConnectingWalletMessage,
    showApprovalMessage,
    showAllowanceApprovedMessage,
    showBlockConfirmationsMessage,
    showBridgeTxSentMessage,
    showErrorMessage,
    showApiErrorMessage,
    showNoMetaMaskMessage,
    showWrongNetworkMessage,
  }
}
