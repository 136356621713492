import React from 'react'
import styled from 'styled-components'
import { useAccountState } from 'store/account/state'
import { TYPE } from 'theme'
import { useHistory } from 'react-router-dom'
import { routes } from 'pages/routes'

const Box = styled.div`
  color: white;
  height: 100vh;
  background-color: #1e2233;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px;
  text-align: center;
`

const Description = styled.div`
  color: white;
  padding: 15px;
`

const NotFound = () => {
  const { isLoading } = useAccountState()

  const history = useHistory()

  if (
    routes.find(
      route => route.path && route.path.includes(history.location.pathname),
    )
  )
    return null

  return (
    <Box>
      {!isLoading && (
        <>
          <TYPE.mediumHeader>Error 404</TYPE.mediumHeader>
          <Description>This page could not be found..</Description>
        </>
      )}
    </Box>
  )
}

export default NotFound
