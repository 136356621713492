import { useEffect } from 'react'
import { socket } from 'api/websocket'

export default function useListenSocketEvent({
  event,
  callback,
  active = true,
}) {
  useEffect(() => {
    if (active) {
      socket.on(event, callback)
    }

    return () => {
      socket.off(event)
    }
  }, [event, callback, active])

  return null
}
