export default function getAlllBridgeCurrencyOptions(bridgeOption) {
  if (!bridgeOption?.tokens) return []

  const {
    bridgeNativeToken,
    bridgeTokenToNative,
    bridgeTokensToToken,
  } = bridgeOption.tokens

  const tokens = []

  if (bridgeNativeToken) tokens.push(bridgeNativeToken)
  if (bridgeTokenToNative) tokens.push(bridgeTokenToNative)
  if (bridgeTokensToToken?.length) tokens.push(...bridgeTokensToToken)

  return tokens
}
