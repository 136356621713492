import styled, { css } from 'styled-components'

export const ButtonBox = styled.button`
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 20px 35px;
  transition: ease-in-out 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.3s;
  }

  ${props =>
    props.buttonStyle === 'highlighted' &&
    css`
      background: linear-gradient(180deg, #2c58df 0%, #3b63db 100%);

      &:hover {
        box-shadow: 0px 0px 25px 2px rgba(44, 88, 223, 0.25);
      }
    `}

  ${props =>
    props.buttonStyle === 'outlined' &&
    css`
      background: none;
      border: 1px solid #949494;
      color: #757575;
      margin: 0 18px 0 0;
    `}
    
    ${props =>
      props.buttonStyle === 'disabled' &&
      css`
        background: linear-gradient(180deg, #2c58df 0%, #3b63db 100%);
        opacity: 50%;
        cursor: not-allowed;
        &:hover {
          transform: none;
          transition: none;
        }
      `}
`

export const ButtonText = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
`
