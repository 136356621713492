import { createState, useState } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

const initialState = createState({
  selectedNetwork: 'doric',
})

export const DORIC_NETWORK = 'doric'
export const ETHEREUM_NETWORK = 'ethereum'

export function useNetworkState() {
  const state = useState(initialState)
  state.attach(Persistence('networkState'))

  return {
    get selectedNetwork() {
      return state.selectedNetwork.get()
    },
    setSelectedNetwork(selectedNetwork) {
      state.selectedNetwork.set(selectedNetwork)
      window.location.reload()
    },
  }
}
