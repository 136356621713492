import React from 'react'
import useScrollPosition from '@react-hook/window-scroll'
import styled from 'styled-components'
import { darken } from 'polished'
import { NavLink } from 'react-router-dom'
import Row, { RowFixed } from '../Row'
import DoricLogo from 'components/DoricLogo'
import { ACCOUNT_DETAILS } from 'store/application/types'
import { useApplicationState } from 'store/application/state'
import { useAccountState } from 'store/account/state'
import AccountDetails from 'components/AccountDetails'
import { device } from 'utils/devices'
import { parseENSAddress } from 'utils/parseENSAddress'
import { ExternalLink, HideSmall, SmallOnly } from '../../theme'
import { Clock, Grid } from 'react-feather'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 0.8rem;
  z-index: 21;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) =>
    showBackground ? '0 -100%' : '0 0'};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px
    ${({ theme, showBackground }) =>
      showBackground ? theme.bg2 : 'transparent;'};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  @media ${device.laptop} {
    padding: 1rem 2rem;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const ButtonShowAccount = styled.p`
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  font-size: 14px;
  margin: 0;
  padding: 10px 15px;

  @media ${device.laptop} {
    font-size: 18px;
    padding: 10px 20px;
  }
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 11px;
  
  `};
`

const HeaderRow = styled(RowFixed)`
  position: relative;

  .mobile {
    display: none;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;

    .larger {
      display: none !important;
    }

    .mobile {
      display: block;
    }
  `};
`

const AccountElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};

  :hover {
    cursor: pointer;
  }
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  width: fit-content;
  padding: 4px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; 
    right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

export const StyledMenuButton = styled(ExternalLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
    text-decoration: none;
  }
`

function Header() {
  const { openPopup, closePopup } = useApplicationState()
  const { address } = useAccountState()

  const scrollY = useScrollPosition()

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <HeaderRow>
        <Title href=".">
          <DoricLogo width="80px" className="mobile" />
          <DoricLogo width="125px" className="larger" />
        </Title>
      </HeaderRow>
      <HeaderLinks>
        <StyledNavLink id={`dashboard-nav-link`} to={'/'} exact>
          <HideSmall>Dashboard</HideSmall>

          <SmallOnly>
            <Grid />
          </SmallOnly>
        </StyledNavLink>

        <StyledNavLink id={`history-nav-link`} to={'/history'}>
          <HideSmall>History</HideSmall>
          <SmallOnly>
            <Clock />
          </SmallOnly>
        </StyledNavLink>
      </HeaderLinks>
      <HeaderControls>
        <HeaderElement>
          {address && (
            <AccountElement
              className="account"
              active
              style={{ pointerEvents: 'auto' }}
              onClick={() =>
                openPopup(ACCOUNT_DETAILS, () => (
                  <AccountDetails
                    toggleWalletModal={() => closePopup(ACCOUNT_DETAILS)}
                  />
                ))
              }
            >
              <ButtonShowAccount id="web3-status-connected">
                {parseENSAddress(address)}
              </ButtonShowAccount>
            </AccountElement>
          )}
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}

export default Header
