import styled, { css } from 'styled-components'
import { device } from 'utils/devices'

export const StepCircle = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 -5px;
  transition: ease-in-out 0.2s;
  transition-delay: 0.2s;
  width: 40px;
  z-index: 4;

  ${props =>
    props.isActive
      ? css`
          background-color: #2c58df;
          color: #fff;
        `
      : css`
          background-color: #f1f1f1;
          color: #cbcbcb;
        `}

	@media ${device.laptop} { 
    height: 60px;
		width: 60px;
  }
`

export const StepNumber = styled.p`
  font-size: 18px;
  font-weight: 600;

  @media ${device.laptop} {
    font-size: 30px;
  }
`

export const StepBar = styled.div`
  background-color: #2c58df;
  border-radius: 0;
  height: 100%;
  transition: ease-in-out 0.3s;
  transition-delay: 0.4s;
  width: 0;
`

export const StepBarBox = styled.div`
  background-color: #f1f1f1;
  height: 12px;
  width: 120px;
	z-index: 2;

  ${props =>
    props.state === 'active' &&
    css`
      ${StepBar} {
        width: 100%;
      }
    `}

  ${props =>
    props.state === 'transitioning' &&
    css`
      ${StepBar} {
        border-radius: 0px 8px 8px 0px;
        width: 50%;
      }
    `}

		${props =>
      props.animationDelayed === false &&
      css`
        ${StepBar} {
          transition-delay: 0s;
        }
      `}
`

export const StepsBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 20px;

  @media ${device.laptop} {
    margin: 0 0 30px;
  }
`

export const StepFormContainer = styled.div``

export const StepFormBox = styled.div`
  background: var(
    --gray-white-fill,
    linear-gradient(225deg, #f0f2f8 0%, #fafafa 30.21%, #fafafa 100%)
  );
  border: 1px solid #b8b8b8;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08) inset,
    0px 4px 25px rgba(0, 0, 0, 0.06);
  max-width: 650px;
  padding: 15px;
  width: 100%;
`

export const FormHeaderBox = styled.div`
  margin: 0;
`

export const FormHeaderTitle = styled.h2`
  font-size: 35px;
  margin: 0 0 18px;
`

export const FormHeaderSubtitle = styled.p`
  font-size: 18px;
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 0;
  width: 100%;
`

export const FormSwitchersBox = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-columns: minmax(0, auto) minmax(0, auto);
`
