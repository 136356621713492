import React from 'react'
import styled from 'styled-components'
import { ExternalLink as LinkIcon } from 'react-feather'
import { device } from 'utils/devices'

import CopyText from '../CopyText'
import { ExternalLink } from '../../theme'
import { parseENSAddress } from 'utils/parseENSAddress'
import { useAccountState } from 'store/account/state'
import { useNetworkState } from 'store/network/state'
import { getExplorerBaseUrl } from 'constants/explorer'
import { parseBalance } from 'utils/balance'

const Content = styled.div`
  width: 100%;
  padding: 40px 40px 10px 10px;

  @media ${device.laptop} {
    padding: 40px 60px 10px 10px;
  }
`

const HeaderRow = styled.div`
  margin: 0 0 50px;
`

const HeaderRowTitle = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin: 0 0 10px;
`

const HeaderRowSubtitle = styled.p`
  font-size: 18px;
  margin: 0;
`

const AccountInfoBox = styled.div``

const AccountControl = styled.div`
  align-items: center;
  display: flex;
  margin: 60px 0 0;
  min-width: 0;
  width: 100%;
  font-size: 18px;

  & > *:first-child {
    margin-right: 15px;
  }

  & > *:first-child,
  & > *:last-child {
    border: 1px solid #dbdbdb;
    border-radius: 12px;
    padding: 10px;
  }

  & a,
  & button {
    align-items: center;
    display: flex;
  }

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AccountInfoContainer = styled.div`
  align-items: center;
  display: flex;

  & > *:first-child {
    margin-right: 40px;
  }
`

const AccountInfoLabel = styled.h3`
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 5px;
`

const AccountInfoValue = styled.p`
  font-size: 18px;
  margin: 0;
`

const AddressLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.text3};

  display: block;
  font-size: 0.825rem;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

function AccountDetails() {
  const { address, balance } = useAccountState()
  const { selectedNetwork } = useNetworkState()

  return (
    <Content>
      <HeaderRow>
        <HeaderRowTitle>Account details</HeaderRowTitle>
        <HeaderRowSubtitle>
          Check you current account information. You can also check you account
          tokens at Doric's Block explorer.
        </HeaderRowSubtitle>
      </HeaderRow>
      <AccountInfoContainer>
        <AccountInfoBox>
          <AccountInfoLabel>Balance</AccountInfoLabel>
          <AccountInfoValue>{parseBalance(balance)}</AccountInfoValue>
        </AccountInfoBox>
        <AccountInfoBox>
          <AccountInfoLabel>Address</AccountInfoLabel>
          <AccountInfoValue>{parseENSAddress(address)}</AccountInfoValue>
        </AccountInfoBox>
      </AccountInfoContainer>
      <AccountControl>
        <CopyText toCopy={address}>
          <span style={{ marginLeft: '6px' }}>Copy Address</span>
        </CopyText>

        <AddressLink
          href={`${getExplorerBaseUrl(selectedNetwork)}/address/${address}`}
        >
          <LinkIcon size={16} />
          <span style={{ marginLeft: '6px' }}>View on Explorer</span>
        </AddressLink>
      </AccountControl>
    </Content>
  )
}

export default AccountDetails
